/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyCell, colors } from '@bonitour/components'
import { pulse } from 'assets/animations/pulse'
import { VacancyPopover } from '../Guides/VacancyPopover'
import { VacancyCardExpanded } from '../../Card/VacancyCard'
import { BookingCardExpanded } from '../Cards/BookingCardExpanded'

const detailedContainerContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const detailedContainer = css`
  padding: 5px;
  vertical-align: top;
`

const cardAnimation = css`
  animation: ${pulse} 0.5s linear;
`

const vacancyCardWarning = css`
  border: 2px solid ${colors.red4};
`

const DetailedContainer = ({ children }) => {
  return (
    <TableBodyCell css={detailedContainer}>
      <div css={detailedContainerContent}>{children}</div>
    </TableBodyCell>
  )
}

export const VacancyTableDetailedCell = ({
  vacancies = {},
  reservations = {},
  rowHour = '',
  date = '',
  isAllowedBookingDate
}) => {
  const { total: totalVacancies = 0 } = vacancies
  const { details: reservationDetails = [] } = reservations
  const hasVacancies = totalVacancies > 0
  const hasNegativeVacancy = totalVacancies < 0

  return (
    <DetailedContainer>

      {hasVacancies && isAllowedBookingDate
        ? (
          <VacancyPopover totalVacancies={totalVacancies} hour={rowHour} date={date}>
            <VacancyCardExpanded css={[cardAnimation]}>{totalVacancies}</VacancyCardExpanded>
          </VacancyPopover>
        )
        : (
          <VacancyCardExpanded css={[cardAnimation, hasNegativeVacancy && vacancyCardWarning]} disabled>
            {totalVacancies}
          </VacancyCardExpanded>
        )}
      {
        reservationDetails.map(({ id, total, commercialPartner, code, payer }) => (
          <BookingCardExpanded
            key={id}
            id={id}
            css={cardAnimation}
            bookingValue={total}
            code={code}
            payer={payer}
            commercialPartner={commercialPartner}
          />
        ))
      }
    </DetailedContainer>
  )
}
