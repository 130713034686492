export const sellingChannelOptions = [
  { value: '', label: 'Todos' },
  { value: 'orb', label: 'Orb' },
  { value: 'xpert', label: 'Xpert' },
  { value: 'xpert_tourist', label: 'Xpert v2' },
  { value: 'ally', label: 'Ally' },
  { value: 'totem', label: 'Totem' },
  { value: 'pos', label: 'Direct' },
  { value: 'limber', label: 'Limber' }
]
