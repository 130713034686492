import { string, array, boolean, number, object } from 'yup'

const minMaxCommissioningTemplate = (a, b) => `A porcentagem de comissionamento deve estar entre ${a} e ${b}`

const requireInvoiceTemplate = (action) => `O dia de ${action} da fatura é obrigatória`

const isDeadlineValid = deadline => deadline > 0

const partnershipGroupBaseSchema = {
  tradeAgreement: object().shape({
    name: string()
      .required('O nome do grupo é obrigatório'),
    commissioning: number()
      .transform((_, v) => parseFloat(String(v).replace(/,/g, '.')))
      .typeError('Deve ser um número')
      .required('O preenchimento do comissionamento é obrigatório')
      .when('netFeeEnabled', {
        is: false,
        then: number().min(0, minMaxCommissioningTemplate(0, 100)).max(100, minMaxCommissioningTemplate(0, 100))
      }),
    sellViaAlly: boolean(),
    netFeeEnabled: boolean(),
    customCommissions: array()
  }),
  invoicePolicy: object().shape({
    invoiced: boolean(),
    deadline: number()
      .transform(value => (isNaN(value) ? 0 : value))
      .test('deadline-mandatory-when-invoiced', 'O deadline é obrigatório para pagamento antecipado', function (value, ctx) {
        const { invoiced } = ctx.parent

        if (!invoiced && ((!value) || (value === 0))) {
          return false
        }

        return true
      })
      .typeError('Deve ser um número'),
    deadlineType: string()
      .test('deadlineType-mandatory-when-deadline', 'O tipo do deadline é obrigatório', function (value, ctx) {
        if (isDeadlineValid(ctx.parent.deadline)) {
          return !!value
        }
        return true
      }),
    closingPeriod: string()
      .required('A sazonalidade de fechamento da fatura é obrigatória'),
    closingDay: string()
      .required(requireInvoiceTemplate('fechamento')),
    paymentDay: string()
      .required(requireInvoiceTemplate('pagamento')),
    invoice: object().shape({
      noShow: boolean(),
      credit: boolean(),
      installments: number().nullable()
    })
  }),
  comercialPartners: array().of(string()),
  customCommissions: array().of(object().shape({
    commission: number()
      .transform((_, v) => parseFloat(String(v).replace(/,/g, '.')))
      .required('A porcentagem de comissão é obrigatória'),
    service: string().required('O serviço é obrigatório'),
    netFeeEnabled: boolean()
  })),
  editable: boolean(),
  id: string()
}

export const editPartnershipGroupSchema = object().shape(partnershipGroupBaseSchema)

export const createPartnershipGroupSchema = object().shape(partnershipGroupBaseSchema)

export const partnershipGroupInviteSchema = object().shape(partnershipGroupBaseSchema)

export const viewPartnershipGroupSchema = object().shape(partnershipGroupBaseSchema)
