/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TicketActivitySummary } from '../../domains/Reservation/Show/Tickets/TicketActivitySummary'
import { AllTicketsSummary } from '../../domains/Reservation/Show/Tickets/AllTicketsSummary'
import { head, identity, tail } from '@bonitour/common-functions'
import { BREAK_POINTS, Card, colors, GhostButton, H3, ToggleInputGroup } from '@bonitour/components'
import { hidden, marginRight } from 'assets/styles/global'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ACTIVITY_TYPE, LIMBER_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'
import { StatusEnum } from 'constants/reservationsStatus'
import { noMargin } from 'domains/Reservation/Show/Tickets/TicketActivitySummary.style'
import orderActivitiesByTime from 'utils/orderActivitiesByTime'

const marginRight20 = marginRight(20)

const ticketsContainer = css`
  background-color: ${colors.white};
  border-radius: 10px;
  padding: 30px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    background: none;
    padding: 0;
    border-radius: 0;
  }
`

const selected = css`
  color: ${colors.primary};
  border-color: ${colors.primary};
`

const toggleContainer = css`
  display: inline-flex !important;
  float: right;
  height: 42px;
  > div {
    flex-direction: row-reverse;
  }
`

const columnBox = css`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
`

const container = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 5rem;
  margin-bottom: 0.5rem;

  @media screen and (max-width: ${BREAK_POINTS.smallTablet}) {
    flex-direction: column;
    align-items: flex-start;
    > section {
      align-items: flex-start;
    }
  }
`

const cardContrast = css`
  padding: 1.4rem;
  background-color: ${colors.white};
  border: none;
  text-align: center;
`

export const TicketActivity = ({
  reservation = [],
  reservationTickets = [],
  pickupTickets = [],
  companyId = '',
  reservationId = '',
  deleteTicketLoading = false,
  ticketConfirmLoading = false,
  linkPassengerLoading = false,
  pickupLocalLoading = false,
  observationLoading = false,
  onTicketDelete = identity,
  onEdit = identity,
  onTicketPrint = identity,
  onTicketPrintVoucherTuris = identity,
  onTicketConfirm = identity,
  onLinkPassenger = identity,
  onUnlinkPassenger = identity,
  onEditPassenger = identity,
  onLinkPickup = identity,
  onUnlinkPickup = identity,
  onEditClickPickup = identity,
  onOpenObservationModal = identity,
  clearObservation = identity,
  onTicketUpdate = identity
}) => {
  const hasActivityType = useCallback((typeName) => {
    const hasSomeTransport = reservationTickets.filter(reservationTicketData => {
      const tickets = tail(reservationTicketData)
      const ticketsTransportType = tickets.filter(({ type }) => type === typeName)
      return ticketsTransportType.length > 0
    })
    return hasSomeTransport.length > 0
  }, [reservationTickets])
  const hasTypeActivity = useMemo(() => hasActivityType(ACTIVITY_TYPE), [hasActivityType])
  const hasTypeTransport = useMemo(() => hasActivityType(TRANSPORT_TYPE), [hasActivityType])
  const hasTypeOfflineExperience = useMemo(() => hasActivityType(OFFLINE_EXPERIENCE_TYPE), [hasActivityType])
  const hasLimberExperience = useMemo(() => hasActivityType(LIMBER_EXPERIENCE_TYPE), [hasActivityType])

  const [serviceTypeFilters, setServiceTypeFilters] = useState([])
  const isTypeFilterSelected = useCallback(type => serviceTypeFilters.includes(type), [serviceTypeFilters])
  const onTypeFilterClick = useCallback(type => () => {
    if (serviceTypeFilters.includes(type)) {
      setServiceTypeFilters(serviceTypeFilters.filter(serviceType => serviceType !== type))
    } else {
      setServiceTypeFilters([...serviceTypeFilters, type])
    }
  }, [serviceTypeFilters])

  useEffect(() => {
    if (hasTypeActivity) {
      setServiceTypeFilters(state => [...state, ACTIVITY_TYPE])
    }
    if (hasTypeTransport) {
      setServiceTypeFilters(state => [...state, TRANSPORT_TYPE])
    }
    if (hasTypeOfflineExperience) {
      setServiceTypeFilters(state => [...state, OFFLINE_EXPERIENCE_TYPE])
    }
    if (hasLimberExperience) {
      setServiceTypeFilters(state => [...state, LIMBER_EXPERIENCE_TYPE])
    }
  }, [hasTypeActivity, hasTypeOfflineExperience, hasTypeTransport, hasLimberExperience])

  const [groupByExperience, setGroupByExperience] = useState(true)
  const toggleGroupByExperience = useCallback(() => setGroupByExperience(state => !state), [])

  const [isHidingCanceledTickets, setIsHidingCanceledTickets] = useState(true)
  const toggleIsHidingCanceledTickets = useCallback(() => setIsHidingCanceledTickets(state => !state), [])

  const ticketsFromReservation = useMemo(
    () => reservation?.tickets?.activities.map(({ tickets }) => tickets).flat(),
    [reservation]
  )

  const fullReservationTickets = useMemo(() => reservationTickets?.map(([date, experiences]) => ([
    date,
    experiences.map(experience => ({
      ...experience,
      tickets: experience.tickets.map(ticket => ({
        ...(ticketsFromReservation?.find(({ id }) => id === ticket.id)),
        ...ticket
      }))
    }))
  ])),
  [reservationTickets, ticketsFromReservation])

  const nonCanceledTickets = useMemo(() => {
    return reservationTickets.map((ticketInfo) => {
      const experiences = ticketInfo[1].reduce((acc, curr) => {
        const tickets = curr.tickets
          .filter(({ state }) => state !== StatusEnum.canceled)

        const filteredPax = Object.keys(curr.pax).reduce((acc, feeName) => {
          const totalTickets = tickets.filter(({ type: feeType }) => (feeType || 'offline') === feeName).length

          acc[feeName] = totalTickets

          return acc
        }, {})

        const filteredTotalAmount = tickets.reduce((acc, { price }) => acc + Number(price || 0), 0)

        const filteredCurr = {
          ...curr,
          pax: filteredPax,
          totalAmount: filteredTotalAmount,
          tickets
        }
        return [filteredCurr, ...acc]
      }, []).filter((exp) => exp?.tickets?.length > 0)

      return [ticketInfo[0], experiences]
    })
  }, [reservationTickets])

  const ticketsData = useMemo(() => {
    const ticketList = (isHidingCanceledTickets ? nonCanceledTickets : fullReservationTickets)
      .map(([date, experiences]) => {
        const orderedExperienceTickets = experiences.map((exp) => {
          const orderedTickets = exp.tickets
            .sort(orderActivitiesByTime)

          return { ...exp, tickets: orderedTickets }
        })
          .sort((a, b) => {
            const timeSort = orderActivitiesByTime(
              a?.tickets[0],
              b?.tickets[0]
            )
            if (timeSort !== 0) {
              return timeSort
            }
            return `${a?.title} ${a?.pickupPlace?.name}`.localeCompare(`${b?.title} ${b?.pickupPlace?.name}`)
          })
        return [date, orderedExperienceTickets]
      })
    return ticketList
  },
  [fullReservationTickets, isHidingCanceledTickets, nonCanceledTickets])

  const allExperiencesData = useMemo(() =>
    ticketsData.reduce(
      (acc, reservationTicketData) => [
        ...acc,
        ...tail(reservationTicketData).map(
          ticket => ({ ...ticket, date: head(reservationTicketData) })
        )],
      []
    ), [ticketsData])

  const hasExperienceData = useMemo(() => allExperiencesData.length > 0, [allExperiencesData])

  return (
    <>
      <div css={container}>
        <div>
          <GhostButton
            css={[marginRight20, !hasTypeActivity && hidden, isTypeFilterSelected(ACTIVITY_TYPE) && selected]}
            onClick={onTypeFilterClick(ACTIVITY_TYPE)}
          >
            Atividade
          </GhostButton>
          <GhostButton
            css={[marginRight20, !hasTypeTransport && hidden, isTypeFilterSelected(TRANSPORT_TYPE) && selected]}
            onClick={onTypeFilterClick(TRANSPORT_TYPE)}
          >
            Transporte
          </GhostButton>
          <GhostButton
            css={[!hasTypeOfflineExperience && hidden, isTypeFilterSelected(OFFLINE_EXPERIENCE_TYPE) && selected]}
            onClick={onTypeFilterClick(OFFLINE_EXPERIENCE_TYPE)}
          >
            Offline
          </GhostButton>
          <GhostButton
            css={[!hasLimberExperience && hidden, isTypeFilterSelected(LIMBER_EXPERIENCE_TYPE) && selected]}
            onClick={onTypeFilterClick(LIMBER_EXPERIENCE_TYPE)}
          >
            Limber
          </GhostButton>
        </div>
        <section css={columnBox}>
          <div css={toggleContainer}>
            <ToggleInputGroup checked={groupByExperience} onChange={toggleGroupByExperience} id='group-by-exp'>
            Agrupar por data e experiência
            </ToggleInputGroup>
          </div>
          <div css={toggleContainer}>
            <ToggleInputGroup checked={isHidingCanceledTickets} onChange={toggleIsHidingCanceledTickets}>
            Ocultar ingressos cancelados
            </ToggleInputGroup>
          </div>
        </section>
      </div>

      {groupByExperience && hasExperienceData
        ? (
          <div css={ticketsContainer}>
            {ticketsData.map((reservationTicketData, index) => (
              <TicketActivitySummary
                key={`ticket-summary-${reservationId}-#${index}`}
                serviceTypeFilters={serviceTypeFilters}
                activityDate={head(reservationTicketData)}
                activityData={tail(reservationTicketData)}
                pickupTickets={pickupTickets}
                reservationId={reservationId}
                onTicketDelete={onTicketDelete}
                deleteTicketLoading={deleteTicketLoading}
                onEdit={onEdit}
                onTicketPrint={onTicketPrint}
                onTicketPrintVoucherTuris={onTicketPrintVoucherTuris}
                reservation={reservation}
                companyId={companyId}
                onTicketConfirm={onTicketConfirm}
                onLinkPassenger={onLinkPassenger}
                onUnlinkPassenger={onUnlinkPassenger}
                onEditPassenger={onEditPassenger}
                onLinkPickup={onLinkPickup}
                onUnlinkPickup={onUnlinkPickup}
                onEditClickPickup={onEditClickPickup}
                ticketConfirmLoading={ticketConfirmLoading}
                linkPassengerLoading={linkPassengerLoading}
                pickupLocalLoading={pickupLocalLoading}
                onOpenObservationModal={onOpenObservationModal}
                clearObservation={clearObservation}
                observationLoading={observationLoading}
                onTicketUpdate={onTicketUpdate}
              />
            ))}
          </div>
        )
        : hasExperienceData
          ? (
            <div css={ticketsContainer}>
              <AllTicketsSummary
                serviceTypeFilters={serviceTypeFilters}
                experiencesData={allExperiencesData}
                pickupTickets={pickupTickets}
                reservationId={reservationId}
                onTicketDelete={onTicketDelete}
                deleteTicketLoading={deleteTicketLoading}
                onEdit={onEdit}
                onTicketPrint={onTicketPrint}
                onTicketPrintVoucherTuris={onTicketPrintVoucherTuris}
                reservation={reservation}
                companyId={companyId}
                onTicketConfirm={onTicketConfirm}
                onLinkPassenger={onLinkPassenger}
                onUnlinkPassenger={onUnlinkPassenger}
                onEditPassenger={onEditPassenger}
                onLinkPickup={onLinkPickup}
                onUnlinkPickup={onUnlinkPickup}
                onEditClickPickup={onEditClickPickup}
                ticketConfirmLoading={ticketConfirmLoading}
                linkPassengerLoading={linkPassengerLoading}
                pickupLocalLoading={pickupLocalLoading}
                onOpenObservationModal={onOpenObservationModal}
                clearObservation={clearObservation}
                observationLoading={observationLoading}
                onTicketUpdate={onTicketUpdate}
              />
            </div>
          )
          : <Card css={cardContrast}>
            <H3 css={noMargin}>Não possui ingressos ativos</H3>
          </Card>
      }
    </>
  )
}
